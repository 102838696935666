import React, { ReactNode } from "react";

import { Hex } from "model-shared";
import { stateBasedRendering } from "./rendering";

export type DebugParameters = {
    readFromFiles: boolean
}

type AnyUniqueString = string

interface PasswordPromptProps {
    readonly articleHolderHtmlId: AnyUniqueString
    readonly siteSalt: Hex,
    readonly isDebug: boolean,
    readonly ifDebug: DebugParameters
}

type PostStatus = "uncertain" | "unrequested" | "begun" | "streaming" | "finished" 

interface PasswordPromptState {
    readonly passwordInput: string
    readonly inputIsReady: boolean
    readonly postStatus: PostStatus
    readonly postData: ReactNode | null
}



export class PasswordPrompt extends React.Component<PasswordPromptProps, PasswordPromptState> {
  constructor(props: PasswordPromptProps) {
    super(props)
    this.state = {
      passwordInput: '',
      inputIsReady: false,
      postStatus: "uncertain",
      postData: null
    }
  }

  render() {
    return (
      <div className="password-prompt">
        { stateBasedRendering(this.props.siteSalt, this) }
      </div>
    );
  }
}