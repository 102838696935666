import React from 'react';
import './App.css';
import { PasswordPrompt } from "./passwordOrPost/passwordOrPost"

/// Randomly generated and here for now
const siteParameters = {
  salt: {hex: "7CDA509FBCA43E4CC423CCF747BEB9A7E66A616E273DEE34198F68400325C45E"},
  articleHolderHtmlId: 'aProbablyUniqueHtmlIdForHoldingTheArticle9138180',
  isDebug: false,
  debugParameters: {
    readFromFiles: true
  }
}

function App() {
  if (siteParameters.isDebug) {
    console.warn("Debug mode enabled!")
  }
  return (
    <div className="App">
      <div className='content-all'>
        <PasswordPrompt
          siteSalt={siteParameters.salt}
          isDebug={siteParameters.isDebug}
          ifDebug={siteParameters.debugParameters}
          articleHolderHtmlId={siteParameters.articleHolderHtmlId}
        />
      </div>
    </div>
  );
}

export default App;