import { ArticlePiece, Hex, InitialArticle } from "model-shared";
import { DeserializationError, previewData } from "../load-post/load-data";
import { PasswordPrompt } from "./passwordOrPost";
import { hybridRender } from "./rendering";

export function passwordInputChanged(promptComponent: PasswordPrompt) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        let newPassword = event.target.value;
        promptComponent.setState({
            passwordInput: newPassword + '',
            inputIsReady: newPassword.length - Math.min(4, countOccurences(newPassword, "-")) === 18
        })
    }
}

// Must be one character or the counting function has to be updated
type OneCharSeparator = '-'

function countOccurences(source: string, ofChar: OneCharSeparator): number {
    return [...source].reduce((count: number, char: string) => char === ofChar ? count + 1 : count, 0)
}

function isDeserializationError(given: InitialArticle | ArticlePiece | DeserializationError): given is DeserializationError {
    return !("kind" in (given as object))
}

export async function performSubmission(siteSalt: Hex, input: string, promptItem: PasswordPrompt) {
        console.log("Form submitted with password ", input)
        localStorage.setItem(window.location.pathname, "")
        promptItem.setState({postStatus: "begun"})

        let generator = await previewData(siteSalt, input, promptItem.props.isDebug ? promptItem.props.ifDebug : undefined)
        if (generator != null) {
            for await (let nextData of generator) {
                if (isDeserializationError(nextData)) { // Deserialization error; reset!
                    console.error("A decryption or decoding error occurred. Abandoning post.")
                    localStorage.setItem(window.location.pathname, "")
                    promptItem.setState({
                        postStatus: "unrequested",
                        passwordInput: "",
                        inputIsReady: false,
                        postData: ""
                    })
                    return
                } else {
                    // Cache the password :)
                    if (localStorage.getItem(window.location.pathname) !== input) {
                        console.log("Apparent success. Caching password.")
                        localStorage.setItem(window.location.pathname, input)
                    }
                    promptItem.setState({
                        passwordInput: "",
                        postStatus: "streaming",
                        postData: hybridRender(nextData)
                    })
                }
            }
            localStorage.setItem(window.location.pathname, input)
            promptItem.setState({ passwordInput: "", postStatus: "finished" })
        } else {
            console.warn("Post not found!")
            promptItem.setState({
                passwordInput: "",
                inputIsReady: false,
                postStatus: "unrequested" 
            })
        }
}

export function validateAndSubmit(siteSalt: Hex, input: string, promptItem: PasswordPrompt): React.FormEventHandler<HTMLFormElement> {
    return async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        performSubmission(siteSalt, input, promptItem)
    }
}