"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aesEncryptor = void 0;
const parameters_1 = require("./parameters");
const generator_1 = require("./generator");
async function aesEncryptor(crypto, toBase64, passcode) {
    let cryptoGenerator = (0, generator_1.generator)(crypto);
    let salt = cryptoGenerator.generateSalt();
    let encryptionKey = await crypto.subtle.deriveKey((0, parameters_1.PBKDF2Parameters)(salt), await (0, parameters_1.passcodeImportParameters)(crypto)(passcode), parameters_1.AESParameters.derivation, false, ["encrypt"]);
    return {
        salt: salt,
        encrypt: (async (data) => {
            let iv = cryptoGenerator.generateIV();
            let encryptedBuffer = await crypto.subtle.encrypt(parameters_1.AESParameters.crypto(iv), encryptionKey, data);
            return {
                iv: iv,
                encryptedBlob: toBase64(encryptedBuffer)
            };
        })
    };
}
exports.aesEncryptor = aesEncryptor;
