import React from "react"

interface WrapperImageProps {
    readonly id: string,
    readonly className: string,
    readonly src: string,
    readonly caption: string
}

interface WrapperImageState {
    readonly src: string
    readonly caption: string
}


class SubwrapperImage extends React.Component<WrapperImageProps, WrapperImageState> {
    constructor(props: WrapperImageProps) {
        super(props)
        this.state = {
          src: props.src,
          caption: props.caption
        }
    } 

    render() {
        return <figure className="article">
            <>
            <img id={this.props.id} className={this.props.className} src={this.state.src} alt={this.state.caption} />
            <figcaption id={`${this.props.id}-caption`} className={this.props.className} aria-hidden="true">{this.state.caption}</figcaption>
            </>
        </figure>
    }
}

export class WrapperImage extends React.Component<WrapperImageProps, WrapperImageState> {
    private subwrapperImage: SubwrapperImage

    updateSubwrapper(caption: string, src: string) {
        this.subwrapperImage.state = {
            src: src,
            caption: caption
        }
        this.subwrapperImage.forceUpdate()
        this.forceUpdate()
    }

    constructor(props: WrapperImageProps) {
      super(props)
      this.subwrapperImage = new SubwrapperImage(props)
    }

    render() {
        return this.subwrapperImage.render()
    }
}