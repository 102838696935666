import React, { ReactNode, useEffect } from "react"
import { PasswordPrompt } from "./passwordOrPost"
import logo from '../logo.svg';
import { passwordInputChanged, performSubmission, validateAndSubmit } from "./submit";
import { ArticleKinds, ArticlePiece, Styled, Header, Hex, InitialArticle, Paragraph, Section } from "model-shared";
import { WrapperImage } from "./wrapperImage";
import {} from 'arrive'

const postUnrequested = (promptItem: PasswordPrompt) =>
  <div>
    <img src={logo} className="App-logo" alt="logo" /> 
    <form onSubmit={validateAndSubmit(promptItem.props.siteSalt, promptItem.state.passwordInput, promptItem)}>
      <p>Please input the password. Dashes are optional :)</p>
      <input className={`password-input ${promptItem.state.inputIsReady ? "input-is-ready" : ""}`}
             minLength={18}
             maxLength={22}
             type="password"
             placeholder="XXX-XXXX-XXXX-XXXX-XXX"
             autoComplete="current-password"
             onChange={passwordInputChanged(promptItem)}
      />
      <div>
      {promptItem.state.inputIsReady 
        ? <input type="submit" className="active-button" value="Submit :)" />
        : <button className="inactive-button">Please input the passcode</button>
      }
      </div>
    </form>
  </div>


const maybeFunnyLoadingLines = [
    `...unless there's a bug. Warranty not included, satisfaction not guaranteed :)`,
    `Did you know I've traveled to at least one 1 country outside of the United States?`,
    `My favorite traveling tip is gatekeeping the best places.`,
    `This blog was a lot of work. Mostly because of my own incompetence.`,
    `help. i'm trapped in a webpage.`
]
const getRandomInt = (n: number) => Math.min(n, Math.floor(Math.random() * n)) // "between 0 and 1"... if it includes 1 we're in trouble

const postRequestBegun = <div>
  <img src={logo} className="App-logo" alt="logo" /> 
  <h1> Loading... </h1>
  <p className="article">This may take some time, but as long as you see this page,  progress is being made!</p>
  <p className="article">{maybeFunnyLoadingLines[getRandomInt(maybeFunnyLoadingLines.length)]}</p>
</div>


const postFinishedStreaming = (promptItem: PasswordPrompt) => promptItem.state.postData


const postReadyDataStreaming = postFinishedStreaming

function PostUncertain({siteSalt, promptItem}: {siteSalt: Hex, promptItem: PasswordPrompt}) {
    useEffect(() => {
        let cachedPassword = window.localStorage.getItem(window.location.pathname)
        if (cachedPassword !== null && cachedPassword.length > 10 && promptItem.state.postStatus === "uncertain") {
            console.log("Cached password found. Attempting to decrypt with password", cachedPassword)
            performSubmission(siteSalt, cachedPassword, promptItem)
        } else {
            promptItem.setState({
                postStatus: "unrequested",
                passwordInput: ""
            })
        }
    })
    
    return <div>
        <img src={logo} className="App-logo" alt="logo" /> 
        <h1> Preparing... </h1>
    </div>
}


export function stateBasedRendering(siteSalt: Hex, promptItem: PasswordPrompt): ReactNode {
    let status = promptItem.state.postStatus
    switch (status) {
        case "uncertain": return <PostUncertain siteSalt={siteSalt} promptItem={promptItem}/>
        case "unrequested": return postUnrequested(promptItem)
        case "begun": return postRequestBegun
        case "streaming": return postReadyDataStreaming(promptItem)
        case "finished": return postFinishedStreaming(promptItem)
    }
}

let imageIdToImage: Map<string, WrapperImage> = new Map() 
let fullArticle: JSX.Element
export function hybridRender(nextElement: InitialArticle | ArticlePiece): ReactNode | string {
    if (typeof nextElement === 'string') {
        return nextElement
    } else {
        switch (nextElement.kind) {
            case 'article':
                nextElement = nextElement as InitialArticle
                let renderedArticle = <article className="article">
                    {nextElement.elements.map(hybridRender)}
                </article>
                fullArticle = renderedArticle
                return renderedArticle
            
            case ArticleKinds.header:
                nextElement = nextElement as Header
                let renderedChildren = nextElement.elements.map(hybridRender)
                switch (nextElement.level) {
                    case 1: return <h1 className="article">{renderedChildren}</h1>
                    case 2: return <h2 className="article">{renderedChildren}</h2>
                    case 3: return <h3 className="article">{renderedChildren}</h3>
                    case 4: return <h4 className="article">{renderedChildren}</h4>
                    case 5: return <h5 className="article">{renderedChildren}</h5>
                    case 6: return <h6 className="article">{renderedChildren}</h6>
                    default:
                        console.error(`Unknown header level ${nextElement.level} found! Aborting.`)
                        imageIdToImage = new Map();
                        return;
                }
            
            case ArticleKinds.section:
                nextElement = nextElement as Section
                return <section className="article">
                    {nextElement.elements.map(hybridRender)}
                </section>

            case ArticleKinds.paragraph:
                nextElement = nextElement as Paragraph
                return <p className="article">
                    {nextElement.elements.map(hybridRender)}
                </p>

            case ArticleKinds.image:
                let nextId : string = nextElement.id
                let nextSrc : string = nextElement.src
                let nextCaption : string = nextElement.caption

                let image: WrapperImage | undefined = imageIdToImage.get(nextId)
                if (image === undefined) {
                    let newImage = new WrapperImage({className: "article", id: nextId, caption: nextCaption, src: nextSrc})
                    imageIdToImage.set(nextId, newImage)
                    return newImage.render()
                } else {
                    document.arrive(`[id="${nextId}"]`, {
                        onceOnly: true, // Only fire one time
                        existing: true // Fire if the element already exists
                    }, (foundImage: Element) => {
                        (foundImage as HTMLImageElement).src = nextSrc;
                        document.arrive(`[id="${nextId}-caption"]`, { onceOnly: true, existing: true },
                            (foundCaption: Element) => { (foundCaption as HTMLSpanElement).innerHTML = nextCaption; }
                        )
                    })
                    return fullArticle
                }
            
            case ArticleKinds.styled:
                nextElement = nextElement as Styled
                // console.log(nextElement.elements)
                let styleProperties: React.CSSProperties = {}
                if (nextElement.styleTypes.includes('bold')) {
                    styleProperties.fontWeight = 'bold'
                }
                if (nextElement.styleTypes.includes('italic')) {
                    styleProperties.fontStyle = 'italic'
                }
                return <span style={styleProperties}>
                    {nextElement.elements.join("")}
                </span>
        }
    }
}