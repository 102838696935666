"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromHex = exports.toHex = exports.hexSet = void 0;
exports.hexSet = "0123456789ABCDEF";
function toHex(bytes) {
    // byte must be in the range 0–255 because bytes is a Uint8 array :)
    return { hex: Array.from(bytes).map((byte) => exports.hexSet.charAt(byte / 16) + exports.hexSet.charAt(byte % 16)).join('') };
}
exports.toHex = toHex;
function fromHex(hex) {
    return Uint8Array.from((hex.hex.match(/.{1,2}/g) ?? ["AA"]).map((byte) => parseInt(byte, 16)));
}
exports.fromHex = fromHex;
