"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generator = void 0;
const encoding_1 = require("./encoding");
const parameters_1 = require("./parameters");
function generator(crypto) {
    function generateRandomBytes(byteCount) {
        return crypto.getRandomValues(new Uint8Array(byteCount));
    }
    // 256 bits
    function generateSalt() {
        return (0, encoding_1.toHex)(generateRandomBytes(32));
    }
    function generateIV() {
        return (0, encoding_1.toHex)(generateRandomBytes(12));
    }
    async function generateFilename(siteSalt, path) {
        return ((0, encoding_1.toHex)(new Uint8Array(await crypto.subtle.deriveBits((0, parameters_1.PBKDF2Parameters)(siteSalt), await (0, parameters_1.passcodeImportParameters)(crypto)(path), 256 // The length of the buffer
        ))).hex + '.json');
    }
    /// The valid characters that comprise my password
    const validGeneratedChars = "a b d e f g h j k m n p q r t u v w x y z A B D E F G H J K M N P Q R T U V W X Y Z 1 2 3 5 6 7 8 9".split(' ');
    // Now we take advantage of the constraints of our password.
    // There are exactly 50 characters. 18 digits means 50^18, or 3814697265625000000000000000000 passwords.
    // To get the number of bytes: log256(50^18) = 13 bytes (104 bits) of randomness
    /// Generates a 100-bit entropy passcode using the above permitted character set
    function generatePasscode() {
        console.log("Generating passcode!");
        let cryptoGen = generator(crypto);
        let possibilities = 50n ** 18n; // 50 characters for an 18 digit password
        let randomBytes = cryptoGen.generateRandomBytes(13);
        // Now limit the max to a multiple of our maximum number of passwords:
        let randomValue = randomBytes.reduce((sum, next_num, index) => sum + (BigInt(next_num) * (2n ** (8n * BigInt(index)))), 0n);
        // let m the maximum number such that m*50^18 < 2^104 :)
        // Then m < 2^104/(50^18) = floor(2^104/(50^18)); we can do log math
        // log m < log(2^104/(50^18)) = log(2^104) - log(50^18) = 104 - 18*log2(50) ~~ 2.410588
        // => m = 5
        // Now to ensure equal distribution we check if our random bytes are in the range of 5*(50 ^ 18) :)
        // So if our random number is greater than 5*50^18, try again (and repeat this until we succeed.)
        if (randomValue >= 5n * possibilities) {
            // I used an integer calculator and found that there is only about a 6% chance of this happening, so we
            // can use rejection sampling :)
            console.log("Initial attempt ", randomValue, " failed!");
            return generatePasscode();
        }
        else {
            let passwordValue = randomValue % possibilities;
            // console.log(passwordValue)
            let base50Nums = convertToBase50Array(passwordValue, 18n);
            // console.log(base50Nums)
            let asCharacters = base50Nums.map(num => validGeneratedChars[Number(num)]).join('');
            // console.log(asCharacters)
            // We want to insert separator at index 3, 7, 11, 15
            let withHyphens = asCharacters.slice(0, 3) + '-' + asCharacters.slice(3, 7) + '-' + asCharacters.slice(7, 11) + '-' + asCharacters.slice(11, 15) + '-' + asCharacters.slice(15);
            return withHyphens;
        }
    }
    function convertToBase50Array(given, length) {
        // console.log(given, length)
        if (length <= 0n) {
            return [];
        }
        let currentPowerifier = (length - 1n);
        let multiplier = 50n ** currentPowerifier;
        return [given / multiplier].concat(convertToBase50Array(given % multiplier, length - 1n));
    }
    return {
        generateRandomBytes,
        generateSalt,
        generateIV,
        generateFilename,
        generatePasscode
    };
}
exports.generator = generator;
