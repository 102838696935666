"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aesDecryptor = void 0;
const parameters_1 = require("./parameters");
async function aesDecryptor(crypto, fromBase64, salt, passcode) {
    let decryptionKey = await crypto.subtle.deriveKey((0, parameters_1.PBKDF2Parameters)(salt), await (0, parameters_1.passcodeImportParameters)(crypto)(passcode), parameters_1.AESParameters.derivation, false, ["decrypt"]);
    return {
        decrypt: (async (iv, data) => {
            return crypto.subtle.decrypt(parameters_1.AESParameters.crypto(iv), decryptionKey, fromBase64(data));
        })
    };
}
exports.aesDecryptor = aesDecryptor;
