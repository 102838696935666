import { EncryptedPostChunk, Hex, AESDecryptor, aesDecryptor, generator, Generator, Base64, ArticlePiece, InitialArticle, ArticleKinds, Header, Section, Paragraph, Image, Styled } from "model-shared"
import { DebugParameters } from "../passwordOrPost/passwordOrPost"
let fetch = globalThis.fetch

let s3BucketURL = "https://personalblogbucketaws0.s3.us-west-2.amazonaws.com/" // Note the presence of a trailing slash

let fixedTextDecoder = new TextDecoder()

/// Uses the path name with PBKDF2 to derive the S3 bucket location
/// This is basically security through obscurity, but it should help
/// reduce annoyances from idle wanderers and bots.
async function deriveLocation(generator: Generator, siteSalt: Hex): Promise<string> {
    let pathLocation: string = window.location.pathname.slice(1)

    return generator.generateFilename(siteSalt, pathLocation)
}

async function * readChunks(input: ReadableStream<Uint8Array>): AsyncGenerator<Uint8Array> {
    let reader = input.getReader()
    while (true) {
        let {done, value}: {done: Boolean, value?: Uint8Array } = await reader.read()
        if (value !== undefined) {
            yield value
        }
        if (done) {
            console.log("Reading chunk stream completed.")
            return
        }
    }
}

// This is a stupid simple helper function that converts the byte input
// to text and then waits to convert to JSON objects
async function * enqueueObjects(input: Iterable<Uint8Array> | AsyncIterable<Uint8Array>): AsyncGenerator<Hex | EncryptedPostChunk> {
    // Horrible statefulness (love making my own finite state machines)
    var currentData: string = ''
    var matchedSalt: boolean = false

    const saltRegExp = /"salt".+?"hex".+?([0-9A-F]+)"/gs
    const valueRegExp = /\{.*?"iv".+?\}.+?\}.*?\}/gs  

    // Results
    for await (let chunk of input) {
        let inputString = fixedTextDecoder.decode(chunk)
        currentData += inputString
        if (!matchedSalt) {
            // The simple 'match' function apparently doesn't work on 
            let saltMatches = currentData.matchAll(saltRegExp)
            let saltMatch = saltMatches.next().value
            if (saltMatch == null) {
                continue
            }
            if (saltMatch.done === false) {
                console.warn("Something strange happened and the iterator is still matching...")
            }
            matchedSalt = true
            let saltValue: string = saltMatch[1]
            currentData = currentData.slice(currentData.lastIndexOf(saltValue) + saltValue.length)
            yield {hex: saltValue}
        }
        if (matchedSalt) {
            let matches = currentData.matchAll(valueRegExp)
            for (let match of matches) {
                let encryptedBlob = match[0]
                let encryptedChunk: EncryptedPostChunk = JSON.parse(encryptedBlob)
                currentData = currentData.slice(currentData.indexOf(encryptedBlob) + encryptedBlob.length)
                yield encryptedChunk
            }
        }
    }
}

async function loadContentFromS3(s3FileName: string, debugParameters?: DebugParameters): Promise<AsyncGenerator<Hex | EncryptedPostChunk> | null> { //Promise<ReadableStream<EncryptedPostContent> | null> {
    let s3FileURL: string
    if (debugParameters?.readFromFiles) {
        console.log("Reading locally!")
        s3FileURL = "/outputs/7a9a629b/" + s3FileName
    } else {
        console.log("Reading from S3!")
        s3FileURL = s3BucketURL + s3FileName
    }
    let s3Response = await fetch(s3FileURL)
    console.log("Response received!")
    let blobResponse: ReadableStream<Uint8Array> | null = s3Response.body
    if (!s3Response.ok || blobResponse == null) return null; // Failure
    console.log("Response okay, proceeding!")

    let asyncGenerator = readChunks(blobResponse)
    return enqueueObjects(asyncGenerator)
}

class DecryptionError {}

function fromBase64(base64: Base64): Uint8Array {
    return _base64ToArrayBuffer(base64.base64)
}

function _base64ToArrayBuffer(base64: string): Uint8Array {
    var binary_string = window.atob(base64)
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

async function * decryptBlobs(userPasscode: string,
                                postData: AsyncGenerator<EncryptedPostChunk | Hex>): AsyncGenerator<string | DecryptionError> | null {
    if (postData != null) {
        let maybeDecryptor: AESDecryptor | undefined 
        try {
            for await (let response of postData) {
                // A hex response, so the salt
                if ('hex' in response) {
                    maybeDecryptor = await aesDecryptor(globalThis.crypto, fromBase64, response, userPasscode)
                    console.log("Decryptor initialized with salt ", response)
                } else {
                    if (maybeDecryptor !== undefined) { // Return our generator
                        try {
                            let decodedValue = fixedTextDecoder.decode(
                                await maybeDecryptor.decrypt(
                                    response.iv, 
                                    response.encryptedBlob
                                )
                            )
                            yield decodedValue
                        } catch (err) {
                            console.error("Error occurred during encryption!: ", err)
                            yield new DecryptionError()
                        }
                    } else {
                        console.error("Received a value before the salt needed to build the decryptor!")
                        return null
                    }
                }
            }
        } catch (err) {
            console.error("Hmm, there was an error: " + err)
            return null
        }  
    }
}

export type DeserializationError = {message: string}

type ArticleKindType = keyof typeof ArticleKinds;

const isOfSubarticleKind = (kindKey: string): kindKey is ArticleKindType => Object.keys(ArticleKinds).includes(kindKey)

async function * stringifyObjects(decryptedBlobs: AsyncGenerator<string | DecryptionError>): AsyncGenerator<InitialArticle | ArticlePiece | DeserializationError> {
    for await (let blob of decryptedBlobs) {
        if (typeof blob !== 'string') {
            console.log("Unknown decryption error occurred!")
            yield {message: "Decryption failed! Stopping deserialization."}
            return
        }
        let parsedObject = JSON.parse(blob)
        let objectKind = parsedObject.kind
        if (objectKind === 'article') {
            yield parsedObject as InitialArticle
        } else if (!isOfSubarticleKind(objectKind)) {
            yield {message: `Deserialization failed! Unknown object encountered of kind ${parsedObject.kind}: ${parsedObject}`}
            return
        } else {
            yield parsedObject as ArticlePiece
            switch (objectKind) {
                case 'header': yield parsedObject as Header; continue;
                case 'section': yield parsedObject as Section; continue;
                case 'paragraph': yield parsedObject as Paragraph; continue;
                case 'image': yield parsedObject as Image; continue;
                case 'styled': yield parsedObject as Styled; continue;
            }
        }
    }
}



async function previewData(siteSalt: Hex, userPasscode: string, debugParameters?: DebugParameters): Promise<AsyncGenerator<InitialArticle | ArticlePiece | DeserializationError> | null> {
    let cryptoGenerator = generator(globalThis.crypto)
    console.log("Site salt", siteSalt)
    let s3FileName: string = await deriveLocation(cryptoGenerator, siteSalt)
    console.log("S3 filename", s3FileName)
    let blobRequestResponse: AsyncGenerator<EncryptedPostChunk | Hex> | null = await loadContentFromS3(s3FileName, debugParameters)
    let decryptedResponse: AsyncGenerator<string | DecryptionError> | null = blobRequestResponse === null ? null : decryptBlobs(userPasscode, blobRequestResponse)
    let stringifiedObjects = decryptedResponse === null ? null : stringifyObjects(decryptedResponse)
    return stringifiedObjects
}

export { previewData }