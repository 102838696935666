"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passcodeImportParameters = exports.AESParameters = exports.PBKDF2Parameters = void 0;
const encoding_1 = require("./encoding");
const PBKDF2Parameters = (salt) => {
    return {
        hash: "SHA-512",
        iterations: 650000,
        salt: (0, encoding_1.fromHex)(salt),
        name: "PBKDF2"
    };
};
exports.PBKDF2Parameters = PBKDF2Parameters;
exports.AESParameters = {
    derivation: {
        length: 256,
        name: "AES-GCM"
    },
    crypto: (iv) => {
        return {
            iv: (0, encoding_1.fromHex)(iv),
            name: exports.AESParameters.derivation.name,
            additionalData: (0, encoding_1.fromHex)(iv)
        };
    }
};
let fixedTextEncoder = new TextEncoder();
let passcodeImportParameters = (crypto) => (passcode) => {
    // For PBKDF2, you simply import the password as the 'key'
    let keyFormat = "raw";
    let algoParameters = { name: "PBKDF2" };
    let keyData = fixedTextEncoder.encode(passcode.replaceAll('-', ''));
    let exportable = false;
    // I guess KeyUsage[] isn't imported properly, so we have to manually specify...
    let functions = ["deriveKey", "deriveBits"];
    return crypto.subtle.importKey(keyFormat, keyData, algoParameters, exportable, functions);
};
exports.passcodeImportParameters = passcodeImportParameters;
