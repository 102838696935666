"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticleKinds = void 0;
var ArticleKinds;
(function (ArticleKinds) {
    ArticleKinds["section"] = "section";
    ArticleKinds["header"] = "header";
    ArticleKinds["paragraph"] = "paragraph";
    ArticleKinds["image"] = "image";
    ArticleKinds["styled"] = "styled";
})(ArticleKinds = exports.ArticleKinds || (exports.ArticleKinds = {}));
